import { createStyles, useMantineTheme, Text, Container, Header, Group, Button, ActionIcon, TextInput, Menu, Modal, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { AccountMenu } from './AccountMenu';
import { AppLogo } from './AppLogo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';
import { IconAdjustments, IconAdjustmentsHorizontal, IconArrowBack, IconChevronLeft, IconDeviceFloppy, IconDotsVertical, IconFileDescription, IconFileSettings, IconHighlight, IconRun, IconSettings } from '@tabler/icons';
import { useContext, useEffect, useState } from 'react';
import { EditorContext } from '../App';

const useStyles = createStyles((theme) => ({
  topNav: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 30,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 20
    }
  },

  hideTopNav: {
    transition: 'transform 0.5s',
    transform: 'translateY(-100%)'
  },

  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  topBarInput: {
    flex: 1
  }
}));

export function HeaderNav() {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const isXS = useMediaQuery(theme.fn.smallerThan('xs').replace('@media ', ''));
  const location = useLocation();
  const doHideNav = location.pathname.match('/editor');
  const doHideArmatureNav = location.pathname.startsWith('/skeletons/editor');
  const doHideSpriteNav = location.pathname.startsWith('/sprites/editor');
  const [isSkeletonMenuOpen, setIsSkeletonMenuOpen] = useState(false);
  const [isSpriteMenuOpen, setIsSpriteMenuOpen] = useState(false);
  const { skeletonEditor, spriteEditor } = useContext(EditorContext);

  // Skeleton editor
  const {
    joints,
    setJoints,
    bones,
    setBones,
    mode: skeletonMode,
    setMode: setSkeletonMode,
    elements
  } = skeletonEditor;

  // Sprite editor
  const {

  } = spriteEditor;

  let locationHeading = "Sprites";

  if (location.pathname.startsWith('/skeletons')) {
    locationHeading = "Skeletons";
  }
  else if (location.pathname.startsWith('/guides')) {
    locationHeading = "Guides";
  }
  else if (location.pathname.startsWith('/settings')) {
    locationHeading = "Settings";
  }



  return (
    <>
      <Header className={cx(classes.topNav, { [classes.hideTopNav]: doHideNav })}>
        <Container className={classes.header}>
          <Group spacing={isXS ? 10 : 20}>
            <AppLogo />
            <Text>{locationHeading}</Text>
          </Group>

          <AccountMenu />
        </Container>
      </Header>

      <Header className={cx(classes.topNav, { [classes.hideTopNav]: !doHideArmatureNav })}>
        <Container className={classes.header}>
          <Group spacing={0}>
            <ActionIcon
              size="xl"
              component={Link}
              to="/skeletons"
            >
              <IconChevronLeft />
            </ActionIcon>

            <Text>Untitled Skeleton</Text>
          </Group>
          <Menu
            opened={isSkeletonMenuOpen}
            onChange={setIsSkeletonMenuOpen}
            closeOnItemClick={false}
            position="bottom-end"
            shadow="md"
            width={230}
            withArrow
            arrowOffset={15}
            arrowSize={12}
            offset={0}
          >
            <Menu.Target>
              <ActionIcon
                size="xl"
              >
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                rightSection={<ThemeToggle />}
                sx={{
                  '&[data-hovered]': {
                    backgroundColor: 'transparent'
                  },
                  cursor: 'unset',
                  fontSize: 12
                }}
              >
                File Menu
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item
                icon={<IconAdjustmentsHorizontal size={14} />}
                onClick={() => setIsSkeletonMenuOpen(false)}
              >
                Properties
              </Menu.Item>

              <Menu.Item
                icon={<IconHighlight size={14} />}
                disabled={Object.keys(elements).length === 0}
                onClick={() => {
                  setSkeletonMode('name-elements');
                  setIsSkeletonMenuOpen(false);
                }}
              >
                Element Names
              </Menu.Item>

            </Menu.Dropdown>
          </Menu>
        </Container>
      </Header>

      <Header className={cx(classes.topNav, { [classes.hideTopNav]: !doHideSpriteNav })}>
        <Container className={classes.header}>
          <Group spacing={0}>
            <ActionIcon
              size="xl"
            >
              <IconRun />
            </ActionIcon>

            <Text>Untitled Sprite</Text>
          </Group>
          <Menu
            opened={isSpriteMenuOpen}
            onChange={setIsSpriteMenuOpen}
            closeOnItemClick={false}
            position="bottom-end"
            shadow="md"
            width={230}
            withArrow
            arrowOffset={15}
            arrowSize={12}
            offset={0}
          >
            <Menu.Target>
              <ActionIcon
                size="xl"
              >
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                rightSection={<ThemeToggle />}
                sx={{
                  '&[data-hovered]': {
                    backgroundColor: 'transparent'
                  },
                  cursor: 'unset',
                  fontSize: 12
                }}
              >
                File Menu
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item
                icon={<IconAdjustmentsHorizontal size={14} />}
                onClick={() => setIsSpriteMenuOpen(false)}
              >
                Properties
              </Menu.Item>

              

            </Menu.Dropdown>
          </Menu>
        </Container>
      </Header>
    </>
  );
}