import { createStyles } from "@mantine/core";
import { createUseGesture, pinchAction } from "@use-gesture/react";
import { useContext, useEffect, useRef, useState } from "react";
import { EditorContext } from "../App";
import { SpriteEditor } from "../pixiApps/SpriteEditor";

const useGesture = createUseGesture([pinchAction]);

const useStyles = createStyles((theme) => ({
  canvasViewport: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    touchAction: 'none'
  },
  

}));

export function SpriteEditorView() {
  const {classes, cx} = useStyles();
  const canvasRef = useRef();
  const [editor, setEditor] = useState(null);
  const {spriteEditor} = useContext(EditorContext);

  const {
    tool,
    pencilColor,
    pencilAlpha,
    brushColor,
    brushAlpha
  } = spriteEditor;

  useEffect(() => {
    const spriteEditor = new SpriteEditor(canvasRef.current);
    setEditor(spriteEditor);
  }, []);

  useEffect(() => {
    if (editor !== null) {
      if (tool === 'pencil') {
        editor.setBrush('pencil');
        editor.setColor(pencilColor);
        editor.setAlpha(pencilAlpha);
      }
      else if (tool === 'brush') {
        editor.setBrush('brush');
        editor.setColor(brushColor);
        editor.setAlpha(brushAlpha);
      }
      else if (tool === 'eraser') {
        // Magically handle erasing
      }
    }
  }, [tool, pencilColor, pencilAlpha, brushColor, brushAlpha]);

  return (
    <div className={classes.canvasViewport}>
      <canvas ref={canvasRef} />
    </div>
  );
}