import { Viewport as ViewportLib } from "pixi-viewport";
import { CustomPIXIComponent } from "react-pixi-fiber/index";

// export function Viewport() {
//   const app = usePixiApp();

//   const behavior = {
//     customDisplayObject: props => new ViewportLib({
//       screenWidth: window.innerWidth,
//       screenHeight: window.innerHeight,
//       worldWidth: 1000,
//       worldHeight: 1000,
//       interaction: app.renderer.plugins.interaction
//     }).drag().pinch().wheel().decelerate(),

//     customApplyProps: (instance, oldProps, newProps) => {
//       console.log('applying props...');
//     }
//   };

//   return CustomPIXIComponent(behavior, "Viewport");
// }

export default CustomPIXIComponent(
  {
    customDisplayObject: (props) => {
      const vp = new ViewportLib({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
        worldWidth: props.width,
        worldHeight: props.height,
        // interaction: ...
      });

      // vp.drag().pinch().decelerate();
      vp.pinch().decelerate();

      vp.on('zoomed-end', (_viewport) => {
        if (typeof props.onZoomEnd === 'function') {
          props.onZoomEnd(_viewport.corner.x, _viewport.corner.y, _viewport.scale.x);
        }
      });

      return vp;
    }
  },
  "Viewport"
);