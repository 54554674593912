import logoLight from '../img/logo-light.svg';
import logoDark from '../img/logo-dark.svg';
import { createStyles, useMantineTheme, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  logo: {
    position: 'relative'
  },

  logoImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'opacity 1s'
  }
}));

export function AppLogo({ xl = false }) {
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const { classes, cx } = useStyles();
  const isXS = useMediaQuery(theme.fn.smallerThan('xs').replace('@media ', ''));
  const xlMulti = (xl ? 3 : 1);

  return (
    <a className={classes.logo} style={{ width: (isXS ? 42 * xlMulti : 62 * xlMulti), height: (isXS ? 42 * xlMulti : 62 * xlMulti)}}>
      <img
        style={{ opacity: (colorScheme === 'dark' ? 1 : 0) }}
        className={classes.logoImg}
        src={logoLight}
        alt="opkm.net"
      />

      <img
        style={{ opacity: (colorScheme === 'light' ? 1 : 0) }}
        className={classes.logoImg}
        src={logoDark}
        alt="opkm.net"
      />
    </a>
  );
}