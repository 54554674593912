import { AspectRatio, Button, createStyles, SimpleGrid, useMantineTheme, Text, Card, Group, ActionIcon, Menu } from "@mantine/core";
import { useMediaQuery, useWindowScroll } from "@mantine/hooks";
import { IconCopy, IconDots, IconDotsVertical, IconPencil, IconPlus, IconRun, IconTrash, IconWalk } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, getDocs, query, limit, deleteDoc, doc } from "firebase/firestore";
import { app, db } from "../libs/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { EditorContext } from "../App";
import { getAuth } from "firebase/auth";

const useStyles = createStyles((theme) => ({
  viewContainer: {
    paddingTop: 73 + 16,
    paddingBottom: 59 + 16,

    [theme.fn.smallerThan('xs')]: {
      paddingTop: 53 + 16
    }
  },
  feedItem: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: (theme.colorScheme === 'light' ? theme.colors.blue[8]: theme.colors.blue[2])
  },
  btnNew: {
    width: '100%',
    maxWidth: 400,
    display: 'block',
    margin: '0 auto'
  }
}));

const auth = getAuth(app);

export function SkeletonsView() {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isXS = useMediaQuery(theme.fn.smallerThan('xs').replace('@media ', ''));
  const xsBreakpoint = parseInt(theme.fn.smallerThan('xs').replace('@media (max-width: ', '').replace('px)', ''), 10);
  const smBreakpoint = parseInt(theme.fn.smallerThan('sm').replace('@media (max-width: ', '').replace('px)', ''), 10);
  const mdBreakpoint = parseInt(theme.fn.smallerThan('md').replace('@media (max-width: ', '').replace('px)', ''), 10);
  // const lgBreakpoint = parseInt(theme.fn.smallerThan('lg').replace('@media (max-width: ', '').replace('px)', ''), 10);
  const [scroll, scrollTo] = useWindowScroll();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { skeletonEditor } = useContext(EditorContext);
  const [listSkeletons, setListSkeletons] = useState({});

  const {
    setSkeletonID
  } = skeletonEditor;

  useEffect(async () => {
    const q = query(collection(db, `/users/${user.uid}/skeletons`), limit(10));

    const qsnap = await getDocs(q);

    let newListSkeletons = {
      ...listSkeletons
    };

    qsnap.forEach((skeleDoc) => {
      newListSkeletons = {
        ...newListSkeletons,
        [skeleDoc.id]: {
          ...skeleDoc.data()
        }
      };
    });

    setListSkeletons(newListSkeletons);
    scrollTo({ y: document.body.scrollHeight });
  }, []);

  async function deleteSkeleton(skeletonID) {
    document.documentElement.classList.add('app-loading');

    await deleteDoc(doc(db, `/users/${user.uid}/skeletons/${skeletonID}`));

    const newListSkeletons = {
      ...listSkeletons
    };

    delete newListSkeletons[skeletonID];

    setListSkeletons(newListSkeletons);

    document.documentElement.classList.remove('app-loading');
  }

  return (
    <div className={classes.viewContainer}>
      <ResponsiveMasonry columnsCountBreakPoints={{
        [xsBreakpoint]: 2,
        [smBreakpoint]: 3,
        [mdBreakpoint]: 4
      }}>
        <Masonry gutter="16px" style={{ alignItems: 'flex-end' }}>
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 150, 200, 0.5)' }} />
          <AspectRatio ratio={16 / 10} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 200, 0.5)' }} />
          <AspectRatio ratio={0.98} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 150, 0.5)' }} />
          <AspectRatio ratio={0.75} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 150, 0.5)' }} />
          <AspectRatio ratio={0.23} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 150, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 150, 200, 0.5)' }} />
          <AspectRatio ratio={16 / 10} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 200, 0.5)' }} />
          <AspectRatio ratio={0.98} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 150, 0.5)' }} />
          <AspectRatio ratio={0.75} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 150, 0.5)' }} />
          <AspectRatio ratio={0.23} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 150, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 150, 200, 0.5)' }} />
          <AspectRatio ratio={16 / 10} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 200, 0.5)' }} />
          <AspectRatio ratio={0.98} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 150, 0.5)' }} />
          <AspectRatio ratio={0.75} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 150, 0.5)' }} />
          <AspectRatio ratio={0.23} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 150, 0.5)' }} />

          {
            Object.keys(listSkeletons).map((_skeletonID) => {
              const _skeleton = listSkeletons[_skeletonID];

              return (
                  <Card
                    shadow="sm"
                    radius="md"
                    withBorder
                    className={classes.cardFix}
                    p="md"
                    key={`skeleton_${_skeletonID}`}
                  >
                    <Card.Section withBorder inheritPadding py="xs">
                      <Group position="apart" sx={{ flexWrap: 'nowrap', alignItems: 'flex-start' }}>
                        <Text size="sm" weight={600}>{_skeleton.name}</Text>

                        <Menu
                          width={150}
                          position="bottom-end"
                          shadow="md"
                          withArrow
                          arrowOffset={9}
                          arrowSize={8}
                          offset={0}
                        >
                          <Menu.Target>
                            <ActionIcon>
                              <IconDotsVertical />
                            </ActionIcon>
                          </Menu.Target>
                          
                          <Menu.Dropdown>
                            <Menu.Item
                              icon={<IconRun size={14} />}
                            >
                              Make Sprite
                            </Menu.Item>

                            <Menu.Item
                              icon={<IconCopy size={14} />}
                            >
                              Duplicate
                            </Menu.Item>

                            <Menu.Divider />

                            <Menu.Item
                              color="red"
                              icon={<IconTrash size={14} />}
                              onClick={() => deleteSkeleton(_skeletonID)}
                            >
                              Delete
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </Group>
                    </Card.Section>

                    <Card.Section>
                      <AspectRatio ratio={1}>
                      </AspectRatio>
                    </Card.Section>

                    <Button
                      leftIcon={<IconPencil />}
                      fullWidth
                    >
                      Edit
                    </Button>
                  </Card>
              );
            })
          }

        </Masonry>
      </ResponsiveMasonry>

      <Button
        className={classes.btnNew}
        leftIcon={<IconPlus />}
        size="xl"
        mt={16}
        color="teal"
        variant="gradient"
        gradient={{
          from: 'teal',
          to: 'green',
          deg: 45
        }}
        onClick={async () => {
          document.documentElement.classList.add('app-loading');

          if (process.env.REACT_APP_doSendData === 'yes') {
            const skeleDocRef = await addDoc(collection(db, `/users/${user.uid}/skeletons`), {
              name: "Untitled Skeleton"
            });
  
            setSkeletonID(skeleDocRef.id);
          }

          document.documentElement.classList.remove('app-loading');
          navigate("/skeletons/editor/new", { replace: false });
        }}
      >
        Create new skeleton
      </Button>

    </div>
  );
}