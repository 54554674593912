import { AspectRatio, Button, createStyles, SimpleGrid, useMantineTheme } from "@mantine/core";
import { useMediaQuery, useWindowScroll } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons";
import { useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  viewContainer: {
    paddingTop: 73 + 16,
    paddingBottom: 59 + 16,

    [theme.fn.smallerThan('xs')]: {
      paddingTop: 53 + 16
    }
  },
  feedItem: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: (theme.colorScheme === 'light' ? theme.colors.blue[8]: theme.colors.blue[2])
  },
  btnNew: {
    width: '100%',
    maxWidth: 400,
    display: 'block',
    margin: '0 auto'
  }
}));

export function SpritesView() {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isXS = useMediaQuery(theme.fn.smallerThan('xs').replace('@media ', ''));
  const xsBreakpoint = parseInt(theme.fn.smallerThan('xs').replace('@media (max-width: ', '').replace('px)', ''), 10);
  const smBreakpoint = parseInt(theme.fn.smallerThan('sm').replace('@media (max-width: ', '').replace('px)', ''), 10);
  const mdBreakpoint = parseInt(theme.fn.smallerThan('md').replace('@media (max-width: ', '').replace('px)', ''), 10);
  // const lgBreakpoint = parseInt(theme.fn.smallerThan('lg').replace('@media (max-width: ', '').replace('px)', ''), 10);
  const [scroll, scrollTo] = useWindowScroll();
  const navigate = useNavigate();

  useEffect(() => {
    scrollTo({ y: document.body.scrollHeight });
  }, []);

  return (
    <div className={classes.viewContainer}>
      <ResponsiveMasonry columnsCountBreakPoints={{
        [xsBreakpoint]: 2,
        [smBreakpoint]: 3,
        [mdBreakpoint]: 4
      }}>
        <Masonry gutter="16px" style={{ alignItems: 'flex-end' }}>
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 150, 200, 0.5)' }} />
          <AspectRatio ratio={16 / 10} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 200, 0.5)' }} />
          <AspectRatio ratio={0.98} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 150, 0.5)' }} />
          <AspectRatio ratio={0.75} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 150, 0.5)' }} />
          <AspectRatio ratio={0.23} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 150, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 150, 200, 0.5)' }} />
          <AspectRatio ratio={16 / 10} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 200, 0.5)' }} />
          <AspectRatio ratio={0.98} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 150, 0.5)' }} />
          <AspectRatio ratio={0.75} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 150, 0.5)' }} />
          <AspectRatio ratio={0.23} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 150, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 150, 200, 0.5)' }} />
          <AspectRatio ratio={16 / 10} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 200, 0.5)' }} />
          <AspectRatio ratio={0.98} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 150, 150, 0.5)' }} />
          <AspectRatio ratio={0.75} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 150, 0.5)' }} />
          <AspectRatio ratio={0.23} className={classes.feedItem} sx={{ backgroundColor: 'rgba(150, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }} />
          <AspectRatio ratio={1} className={classes.feedItem} sx={{ backgroundColor: 'rgba(200, 200, 150, 0.5)' }} />

        </Masonry>
      </ResponsiveMasonry>

      <Button
        className={classes.btnNew}
        leftIcon={<IconPlus />}
        size="xl"
        mt={16}
        color="teal"
        variant="gradient"
        gradient={{
          from: 'teal',
          to: 'green',
          deg: 45
        }}
        onClick={() => {
          navigate("/sprites/editor/new", { replace: false });
        }}
      >
        Create new sprite
      </Button>

    </div>
  );
}