import { Menu } from "@mantine/core";
import { IconBook, IconCheck, IconLogout, IconSettings } from "@tabler/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ThemeToggle } from "./ThemeToggle";
import { getAuth, signOut } from 'firebase/auth';
import { app } from "../libs/firebase";
import { showNotification } from "@mantine/notifications";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(app);

export function AccountMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [user] = useAuthState(auth);

  async function doLogout() {
    await signOut(getAuth(app));

    showNotification({
      title: "Logout success",
      message: "You are now logged out",
      color: "teal",
      icon: <IconCheck />
    });
  }

  return (
    <>
      <Menu
        opened={isOpen}
        onChange={setIsOpen}
        closeOnItemClick={false}
        position="bottom-end"
        shadow="md"
        width={230}
        withArrow
        arrowOffset={11}
        arrowSize={12}
        offset={0}
      >
        <Menu.Target>
          <img
            src={user.photoURL}
            style={{ width: 38, height: 38, borderRadius: '100%' }}
            referrerPolicy="no-referrer"
          />
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            rightSection={<ThemeToggle />}
            sx={{
              '&[data-hovered]': {
                backgroundColor: 'transparent'
              },
              cursor: 'unset',
              fontSize: 12
            }}
          >
            {user.email}
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            icon={<IconSettings size={14} />}
            component={Link}
            to="/settings"
            onClick={() => setIsOpen(false)}
          >
            App Settings
          </Menu.Item>

          <Menu.Item
            icon={<IconBook size={14} />}
            component={Link}
            to="/guides"
            onClick={() => setIsOpen(false)}
          >
            Guide Book
          </Menu.Item>

          <Menu.Item
            icon={<IconLogout size={14} />}
            onClick={() => doLogout()}
          >
            Logout
          </Menu.Item>

        </Menu.Dropdown>
      </Menu>
    </>
  );
}