import * as PIXI from 'pixi.js';
import { CustomPIXIComponent } from 'react-pixi-fiber/index';

export default CustomPIXIComponent(
  {
    customDisplayObject: (props) => {
      const bone = new PIXI.Graphics();
      bone.interactive = true;

      return bone;
    },
    customApplyProps: (bone, oldProps, newProps) => {
      const {pos1, pos2, willDelete, willName, colorScheme} = newProps;

      bone.clear();

      if (willName) {
        bone.lineStyle(2, 0x0000FF, 1);
      }
      else if (willDelete) {
        bone.lineStyle(2, 0xFF0000, 1);
      }
      else {
        if (colorScheme === 'light') {
          bone.lineStyle(2, 0x000000, 1);
        }
        else if (colorScheme === 'dark') {
          bone.lineStyle(2, 0xFFFFFF, 1);
        }
      }

      bone.moveTo(pos1.x, pos1.y);
      bone.lineTo(pos2.x, pos2.y);

      // if (typeof oldProps !== 'undefined') {
      //   node.off('pointerdown', oldProps.pointerdown);
      // }

      // node.on('pointerdown', newProps.pointerdown);
    }
  },
  "SkeletonBone"
);