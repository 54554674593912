import { Button, createStyles, PasswordInput, Text, TextInput } from "@mantine/core";
import { hideNotification, showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { AppLogo } from "../components/AppLogo";
import { ThemeToggle } from "../components/ThemeToggle";
import logoGoogle from "../img/logo-google.svg";
import { getAuth } from 'firebase/auth';
import { app, db } from "../libs/firebase";
import { doc, getDoc, setDoc } from 'firebase/firestore';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  topBar: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 16
  },

  input: {
    width: '100%',
    maxWidth: 300
  },

  btnGoogle: {
    width: '100%',
    maxWidth: 300,
    color: (theme.colorScheme === 'light' ? theme.colors.gray[8] : theme.colors.gray[2]),
    borderColor: (theme.colorScheme === 'light' ? theme.colors.gray[8] : theme.colors.gray[2])
  }
}));

export function LoginView(auth) {
  const {classes, cx} = useStyles();
  
  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    
    try {
      const result = await signInWithPopup(getAuth(app), provider);

      showNotification({
        title: "Login success",
        message: `Logged in as ${result.user.displayName}`,
        color: "teal",
        icon: <IconCheck />
      });

      const userDocSnap = await getDoc(doc(db, `/users/${result.user.uid}`));

      if (!userDocSnap.exists()) {
        showNotification({
          id: 'account-setup-notification',
          message: "Initializing account...",
          disallowClose: true,
          color: "blue",
          loading: true
        });

        await setDoc(doc(db, `/users/${result.user.uid}`), {
          displayName: result.user.displayName
        });

        hideNotification('account-setup-notification');
      }
    }
    catch (e) {
      console.error(e);

      showNotification({
        title: "Failed to login with Google",
        message: `Failed with message: "${e.message}"`,
        color: "red",
        icon: <IconX />
      });
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.topBar}>
        <ThemeToggle />
      </div>

      <AppLogo xl />

      <Button
        variant="outline"
        size="md"
        mt={60}
        leftIcon={<img src={logoGoogle} alt="" />}
        className={classes.btnGoogle}
        onClick={() => signInWithGoogle()}
      >
        Continue with Google
      </Button>

      <Text size="lg" my={20}>
        or
      </Text>

      <TextInput
        placeholder="you@awesome.com"
        label="Email"
        type="email"
        className={classes.input}
        mb={10}
      />

      <PasswordInput
        placeholder="yourtopsecretpassword123"
        label="Password"
        className={classes.input}
        mb={10}
      />

      <Button mt={10} className={classes.input}>
        Login
      </Button>
    </div>
  );
}