import { useContext, useState } from 'react';
import { createStyles, Footer, Container, Group, Anchor, useMantineTheme, ColorSwatch, Menu, ColorPicker, AlphaSlider, Text, HueSlider, ActionIcon, TextInput, Button, Center } from '@mantine/core';
import { NavLink, useLocation } from 'react-router-dom';
import { IconBrush, IconChartDots3, IconChevronLeft, IconChevronRight, IconCircleOff, IconEditCircle, IconEraser, IconInfoCircle, IconPencil, IconPointOff } from '@tabler/icons';
import { EditorContext } from '../App';
import { ThemeToggle } from './ThemeToggle';

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 4,
    position: 'fixed',
    top: 'unset',
    bottom: 0,
    left: 0,
    width: '100%'
  },

  hideBottomNav: {
    transition: 'transform 0.5s',
    transform: 'translateY(100%)'
  },

  containerArtBar: {
    zIndex: 3,
    position: 'fixed',
    top: 'unset',
    bottom: 0,
    left: 0,
    width: '100%',
    transform: 'translateY(100%)',
  },

  showArtBar: {
    transition: 'transform 0.5s',
    transform: 'translateY(0)'
  },

  containerArmatureBar: {
    zIndex: 3,
    position: 'fixed',
    top: 'unset',
    bottom: 0,
    left: 0,
    width: '100%',
    transform: 'translateY(100%)',
  },

  showArmatureBar: {
    transition: 'transform 0.5s',
    transform: 'translateY(0)'
  },

  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }
  },

  toolActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },

  linkLabel: {
    marginLeft: 5,
    pointerEvents: 'none',

    [theme.fn.smallerThan('xs')]: {
      display: 'none'
    }
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      // background: 'red !important'
    }
  },

  elementNamer: {
    zIndex: 2,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    
    transform: 'translateY(100%)',
    transition: 'transform 0.5s'
  },

  showElementNamer: {
    transform: 'translateY(0)'
  },

  elementNamerGroup: {
    width: '100%'
  },

  elementNamerDoneBtn: {
    zIndex: 2,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '6px 16px',
    
    transform: 'translateY(100%)',
    transition: 'transform 1s'
  },

  showElementNamerDoneBtn: {
    transform: 'translateY(-59px)',
  }
}));

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export function FooterNav({ links }) {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const location = useLocation();
  const doShowSpriteBar = location.pathname.startsWith('/sprites/editor');
  const doShowArmatureBar = location.pathname.startsWith('/skeletons/editor');
  const doHideNav = (doShowSpriteBar || doShowArmatureBar);
  const [currentColorPencil, setCurrentColorPencil] = useState(theme.colors.grape[5]);
  const [alphaPencil, setAlphaPencil] = useState(1);
  const [currentColorBrush, setCurrentColorBrush] = useState(theme.colors.grape[5]);
  const [alphaBrush, setAlphaBrush] = useState(1);
  const [colorPickerOpen, setColorPickerOpenState] = useState(false);
  const { skeletonEditor, spriteEditor } = useContext(EditorContext);

  const {
    tool: skeletonTool,
    setTool: setSkeletonTool,
    mode: skeletonEditorMode,
    setMode: setSkeletonEditorMode,
    elements,
    setElements,
    selectedElementIndex,
    setSelectedElementIndex
  } = skeletonEditor;

  const {
    tool: spriteTool,
    setTool: setSpriteTool,
    pencilColor,
    setPencilColor,
    pencilAlpha,
    setPencilAlpha,
    brushColor,
    setBrushColor,
    brushAlpha,
    setBrushAlpha
  } = spriteEditor;

  const selectedElementKey = Object.keys(elements)[selectedElementIndex];
  
  const [activeTool, setActiveTool] = useState(
    doShowArmatureBar
    ? skeletonTool
    : spriteTool
  );

  const setColorPickerOpen = (isOpen) => {
    if (spriteTool === 'eraser') {
      setColorPickerOpenState(false);
    }
    else {
      setColorPickerOpenState(isOpen);
    }
  };

  let currentColor = pencilColor;
  let alpha = pencilAlpha;
  let setCurrentColor = setPencilColor;
  let setAlpha = setPencilAlpha;

  if (spriteTool === 'brush') {
    currentColor = brushColor;
    alpha = brushAlpha;
    setCurrentColor = setBrushColor;
    setAlpha = setBrushAlpha;
  }
  else if (spriteTool === 'eraser') {
    currentColor = '#FFFFFF';
    alpha = 0;
    setCurrentColor = () => {};
    setAlpha = () => {};
  }

  const activeLinkStyle = {
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
  };

  const items = links.map((link) => (
    <NavLink
      key={link.label}
      to={link.link}
      className={classes.link}
      style={({ isActive }) =>
        isActive ? activeLinkStyle : undefined
      }
    >
      {link.icon}
      <label className={classes.linkLabel}>{link.label}</label>
    </NavLink>
  ));

  function handleUpdateElementName(key, value) {
    setElements({
      ...elements,
      [key]: {
        ...elements[key],
        name: value
      }
    });
  }

  return (
    <>
      <Footer height={60} className={cx(classes.container, { [classes.hideBottomNav]: doHideNav })}>
        <Container className={classes.footer}>
          <Group spacing={5} className={classes.links}>
            {items}
          </Group>

        </Container>
      </Footer>

      <Footer height={60} className={cx(classes.containerArtBar, { [classes.showArtBar]: doShowSpriteBar })}>
        <Container className={classes.footer}>
          <Group spacing={5} className={classes.links}>
            <a
              className={cx(classes.link, { [classes.toolActive]: (spriteTool === 'pencil') })}
              onClick={(e) => {
                e.preventDefault();
                setSpriteTool('pencil');
              }}
            >
              <IconPencil />
              <label className={classes.linkLabel}>Pencil</label>
            </a>

            <a
              className={cx(classes.link, { [classes.toolActive]: (spriteTool === 'brush') })}
              onClick={(e) => {
                e.preventDefault();
                setSpriteTool('brush');
              }}
            >
              <IconBrush />
              <label className={classes.linkLabel}>Brush</label>
            </a>

            <a
              className={cx(classes.link, { [classes.toolActive]: (spriteTool === 'eraser') })}
              onClick={(e) => {
                e.preventDefault();
                setSpriteTool('eraser');
              }}
            >
              <IconEraser />
              <label className={classes.linkLabel}>Eraser</label>
            </a>

            <Menu opened={colorPickerOpen} onChange={setColorPickerOpen}>
              <Menu.Target>
                <a
                  className={classes.link}
                  onClick={(e) => {
                    e.preventDefault();
                    // setActiveTool('eraser');
                  }}
                  disabled={(spriteTool === 'eraser')}
                  style={{ opacity: (spriteTool === 'eraser' ? 0.25 : 1) }}
                >
                  <ColorSwatch color={hexToRGB(currentColor, alpha)}  />
                  <label className={classes.linkLabel}>Colour</label>
                </a>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item icon={<IconInfoCircle />} disabled>Press any colour on your sprite to select it.</Menu.Item>
                <Menu.Divider />
                <Menu.Label>
                  <ColorPicker
                    format="hex"
                    value={currentColor}
                    onChange={setCurrentColor}
                    fullWidth
                    size="xl"
                  />
                  <AlphaSlider mt={10} size="xl" color={currentColor} value={alpha} onChange={setAlpha} />
                </Menu.Label>
              </Menu.Dropdown>
            </Menu>
          </Group>

        </Container>
      </Footer>

      <Center className={cx(classes.elementNamerDoneBtn, { [classes.showElementNamerDoneBtn]: (doShowArmatureBar && skeletonEditorMode === 'name-elements') })}>
        <Button
          fullWidth
          onClick={() => setSkeletonEditorMode('build')}
        >
          Done
        </Button>
      </Center>

      <Footer height={60} className={cx(classes.elementNamer, { [classes.showElementNamer]: (doShowArmatureBar && skeletonEditorMode === 'name-elements') })}>
        <Container className={classes.footer}>
          <Group spacing={10} className={classes.elementNamerGroup}>
            <ActionIcon
              size="xl"
              disabled={Object.keys(elements).length <= 1}
              onClick={() => {
                if (selectedElementIndex > 0) {
                  setSelectedElementIndex(selectedElementIndex - 1);
                }
                else {
                  setSelectedElementIndex(Object.keys(elements).length - 1);
                }
              }}
            >
              <IconChevronLeft />
            </ActionIcon>
            
            <TextInput
              placeholder="Element Name"
              value={elements[selectedElementKey]?.name || ""}
              onChange={(e) => handleUpdateElementName(selectedElementKey, e.target.value)}
              sx={{ flex: 1 }}
            />

            <ActionIcon
              size="xl"
              disabled={Object.keys(elements).length <= 1}
              onClick={() => {
                if (selectedElementIndex < Object.keys(elements).length - 1) {
                  setSelectedElementIndex(selectedElementIndex + 1);
                }
                else {
                  setSelectedElementIndex(0);
                }
              }}
            >
              <IconChevronRight />
            </ActionIcon>
          </Group>
        </Container>
      </Footer>

      <Footer height={60} className={cx(classes.containerArmatureBar, { [classes.showArmatureBar]: (doShowArmatureBar && skeletonEditorMode === 'build') })}>
        <Container className={classes.footer}>
          <Group spacing={5} className={classes.links}>
            <a
              className={cx(classes.link, { [classes.toolActive]: (skeletonTool === 'plot') })}
              onClick={(e) => {
                e.preventDefault();
                setSkeletonTool('plot');
              }}
            >
              <IconEditCircle />
              <label className={classes.linkLabel}>Plot</label>
            </a>

            <a
              className={cx(classes.link, { [classes.toolActive]: (skeletonTool === 'connect') })}
              onClick={(e) => {
                e.preventDefault();
                setSkeletonTool('connect');
              }}
            >
              <IconChartDots3 />
              <label className={classes.linkLabel}>Connect</label>
            </a>

            <a
              className={cx(classes.link, { [classes.toolActive]: (skeletonTool === 'unplot') })}
              onClick={(e) => {
                e.preventDefault();
                setSkeletonTool('unplot');
              }}
            >
              <IconPointOff />
              <label className={classes.linkLabel}>Unplot</label>
            </a>
          </Group>
        </Container>
      </Footer>
    </>
  );
}