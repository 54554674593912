import * as PIXI from 'pixi.js';

export class SpriteEditor {
  constructor(canvasRef) {
    this.activeColor = 0x000000;
    this.activeAlpha = 1;
    this.maxUndoSteps = 10;
    this.canvas = canvasRef;

    this.app = new PIXI.Application({
      width: 0,
      height: 0,
      resizeTo: window, // this acts whacky sometimes (resizing desktop -> mobile)
      view: this.canvas,
      backgroundAlpha: 0,
    });
    
    // Init brush sprite
    this.brush = PIXI.Sprite.from('/brushes/pencil-basic.png');

    // Enable direct canvas interaction
    this.app.stage.hitArea = new PIXI.Rectangle(0, 0, window.innerWidth, window.innerHeight);
    this.app.stage.interactive = true;

    this.stroke = new PIXI.Container();
    this.undoPaper = new PIXI.Container();
    this.paper = new PIXI.Container();

    this.paper.addChild(new PIXI.Sprite());

    this.app.stage.addChild(this.paper);
    this.app.stage.addChild(this.undoPaper);
    this.app.stage.addChild(this.stroke);


    this.pointerType = null;
    this.handlePointerDown = this.handlePointerDown.bind(this);
    this.handlePointerMove = this.handlePointerMove.bind(this);
    this.handlePointerUp = this.handlePointerUp.bind(this);
    
    this.app.stage.on('pointerdown', this.handlePointerDown);
    this.app.stage.on('pointermove', this.handlePointerMove);
    this.app.stage.on('pointerup', this.handlePointerUp);
  }

  handlePointerDown(e) {
    // const {x, y} = e.data.global;
  
    if (this.pointerType === null) {
      this.pointerType = e.data.pointerType;
    }
  }

  handlePointerMove(e) {
    const {x, y} = e.data.global;
  
    if (this.pointerType === null) {
      // Pen is hovering
      // console.log('align or something?');
    }
    else if (this.pointerType === 'pen') {
      const {pressure} = e.data;
  
      const brushStroke = PIXI.Sprite.from(this.brush.texture);
  
      brushStroke.x = x;
      brushStroke.y = y;
      // brushStroke.alpha = (0.5 * pressure);
      brushStroke.alpha = this.activeAlpha * (1 - pressure);
      brushStroke.anchor.x = 0.5;
      brushStroke.anchor.y = 0.5;
      brushStroke.angle = this.getRandomInt(0, 360);
      brushStroke.scale.x = 0.25 * pressure;
      brushStroke.scale.y = 0.25 * pressure;
      brushStroke.tint = this.activeColor;
      
      this.stroke.addChild(brushStroke);
    }
    else if (this.pointerType === 'touch') {
      
    }
  }

  handlePointerUp(e) {
    if (this.pointerType === 'pen') {
      // Generate texture from stroke, and add to paper container
      const {x, y} = this.stroke.getLocalBounds();
      const texture = this.app.renderer.generateTexture(this.stroke);
      const strokeSprite = PIXI.Sprite.from(texture);
  
      strokeSprite.anchor.x = 0;
      strokeSprite.anchor.y = 0;
  
      strokeSprite.x = x;
      strokeSprite.y = y;
  
      // paper.addChild(strokeSprite);
  
      // If undoPaper's children exceeds the maxUndoSteps length, remove the first child and render it to the finalized paper
      if (this.undoPaper.children.length === this.maxUndoSteps) {
        const strokeToCommit = this.undoPaper.removeChildAt(0);
  
        this.paper.addChild(strokeToCommit);
        
        const paperTexture = this.app.renderer.generateTexture(this.paper);
        const paperSprite = PIXI.Sprite.from(paperTexture);
  
        this.paper.removeChildren().forEach((child) => {
          child.destroy();
        });
  
        this.paper.addChild(paperSprite);
      }
  
      this.undoPaper.addChild(strokeSprite);
  
      console.log(this.undoPaper.children.length);
  
      // if (undoPaper.children.length === 1) {
      //   const {x: upx, y: upy} = undoPaper.getLocalBounds();
  
      //   paper.x = upx;
      //   paper.y = upy;
      // }
  
      // Remove all children from stroke container
      this.stroke.removeChildren().forEach((child) => {
        child.destroy();
      });
    }
  
    this.pointerType = null;
  }

  setBrush(brushKey) {

  }

  setColor(colorStr) {
    this.activeColor = PIXI.utils.string2hex(colorStr);
  }

  setAlpha(alpha) {
    this.activeAlpha = alpha;
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}