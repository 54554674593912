import { createContext, useState } from 'react';
import { MantineProvider, ColorSchemeProvider, Text, Container, LoadingOverlay } from '@mantine/core';
import { FooterNav } from './components/FooterNav';
import { IconRun, IconBone } from '@tabler/icons';
import { HeaderNav } from './components/HeaderNav';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { NotificationsProvider } from '@mantine/notifications';
import { app } from './libs/firebase';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

// Views
import { SpritesView } from './views/Sprites';
import { SpriteEditorView } from './views/SpriteEditor';
import { SkeletonsView } from './views/Skeletons';
import { SkeletonEditorView } from './views/SkeletonEditor';
import { LoginView } from './views/Login';

export const EditorContext = createContext();

const auth = getAuth(app);

export default function App() {
  const [colorScheme, setColorScheme] = useState('light');
  const toggleColorScheme = (value) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  const [user, loading, error] = useAuthState(auth);

  // Skeleton editor
  const [skeletonTool, setSkeletonTool] = useState('plot');
  const [skeletonMode, setSkeletonMode] = useState('build');
  const [joints, setJoints] = useState({
    // [id]: {
    //   x: 0,
    //   y: 0
    // }
  });
  const [bones, setBones] = useState({
    // [id]: {
    //   id1: 0,
    //   id2: 0
    // }
  });
  const [elements, setElements] = useState({
    // [id]: {
    //   name: "",
    //   jointIDs: [],
    //   boneIDs: []
    // }
  });
  const [selectedElementIndex, setSelectedElementIndex] = useState(0);
  const [skeletonID, setSkeletonID] = useState(null);
  const [skeletonName, setSkeletonName] = useState("Untitled Skeleton");

  // Sprite editor
  const [spriteTool, setSpriteTool] = useState('pencil');
  const [pencilColor, setPencilColor] = useState('#000000');
  const [pencilAlpha, setPencilAlpha] = useState(1);
  const [brushColor, setBrushColor] = useState('#00FFFF');
  const [brushAlpha, setBrushAlpha] = useState(1);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{
        colorScheme,
        defaultGradient: {
          from: 'teal',
          to: 'grean',
          deg: 45
        }
      }} withGlobalStyles withNormalizeCSS>
        <NotificationsProvider>
          <EditorContext.Provider value={{
            skeletonEditor: {
              tool: skeletonTool,
              setTool: setSkeletonTool,
              joints,
              setJoints,
              bones,
              setBones,
              elements,
              setElements,
              mode: skeletonMode,
              setMode: setSkeletonMode,
              selectedElementIndex,
              setSelectedElementIndex,
              skeletonID,
              setSkeletonID,
              name: skeletonName,
              setName: setSkeletonName
            },
            spriteEditor: {
              tool: spriteTool,
              setTool: setSpriteTool,
              pencilColor,
              setPencilColor,
              pencilAlpha,
              setPencilAlpha,
              brushColor,
              setBrushColor,
              brushAlpha,
              setBrushAlpha
            }
          }}>
            <Router>
              <div>
                {
                  user
                  ? <EditorContext.Consumer>
                      {
                        value => <HeaderNav />
                      }
                    </EditorContext.Consumer>
                  : null
                }

                {
                  user
                  ? <Routes>
                      <Route exact path="/sprites" element={<Container><SpritesView /></Container>} />
                      <Route path="/sprites/editor/*" element={<SpriteEditorView />} />
                      <Route exact path="/skeletons" element={<Container><EditorContext.Consumer>{ value => <SkeletonsView /> }</EditorContext.Consumer></Container>} />
                      <Route path="/skeletons/editor/*" element={<EditorContext.Consumer>{ value => <SkeletonEditorView /> }</EditorContext.Consumer>} />

                      <Route exact path="/guides" element={<Container><Text mt={100}>Guides dashboard.</Text></Container>} />
                      <Route exact path="/settings" element={<Container><Text mt={100}>Settings dashboard.</Text></Container>} />

                      <Route
                        path="*"
                        element={<Navigate to="/skeletons" replace />}
                      />
                    </Routes>
                  : <Routes>
                      <Route exact path="/" element={<Container><LoginView auth={auth} /></Container>} />

                      <Route
                        path="*"
                        element={<Navigate to="/" replace />}
                      />
                    </Routes>
                }
                

                {
                  user
                  ? <EditorContext.Consumer>
                      {
                        value =>
                          <FooterNav links={[
                            {
                              label: "Skeletons",
                              link: "/skeletons",
                              icon: <IconBone />
                            },
                            {
                              label: "Sprites",
                              link: "/sprites",
                              icon: <IconRun />
                            },
                            // {
                            //   label: "Tilesets",
                            //   link: "/tilesets",
                            //   icon: <IconBoxModel2 />
                            // },
                            // {
                            //   label: "Voiceovers",
                            //   link: "/voiceovers",
                            //   icon: <IconMicrophone />
                            // }
                          ]} />
                      }
                    </EditorContext.Consumer>
                  : null
                }
              </div>
            </Router>
          </EditorContext.Provider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}