import { createStyles, useMantineColorScheme } from "@mantine/core";
import * as PIXI from "pixi.js";
import { Stage, Sprite, usePixiApp } from "react-pixi-fiber/index";
import { useContext, useEffect, useState } from "react";
import Viewport from '../components/pixi/Viewport';
import SkeletonJoint from "../components/pixi/SkeletonJoint";
import { EditorContext } from "../App";
import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import SkeletonBone from "../components/pixi/SkeletonBone";
import { doc, setDoc } from 'firebase/firestore';
import { app, db } from "../libs/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";

const useStyles = createStyles((theme) => ({
  canvasViewport: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    touchAction: 'none'
  }
}));

const auth = getAuth(app);

export function SkeletonEditorView() {
  const {classes, cx} = useStyles();

  const { skeletonEditor } = useContext(EditorContext);
  const {
    tool,
    joints,
    setJoints,
    bones,
    setBones,
    elements,
    setElements,
    mode,
    selectedElementIndex,
    skeletonID,
    name,
    setName
  } = skeletonEditor;

  const [nextElementID, setNextElementID] = useState(0);

  const [nextJointID, setNextJointID] = useState(0);
  const [dragJointID, setDragJointID] = useState(-1);
  const [holdTimer, setHoldTimer] = useState(null);
  const [zoomOffset, setZoomOffset] = useState({
    x: 0,
    y: 0,
    z: 1
  });
  const [touchPos, setTouchPos] = useState({ x: null, y: null });
  const [connectingJointID, setConnectingJointID] = useState(-1);
  const [nextBoneID, setNextBoneID] = useState(0);
  const [deleteJointID, setDeleteJointID] = useState(-1);
  const {colorScheme} = useMantineColorScheme();
  const [user] = useAuthState(auth);

  // Firestore autosaving
  useEffect(() => {
    // if (typeof skeletonID === 'string' && process.env.REACT_APP_doSendData === 'yes') {
    //   setDoc(doc(db, `/users/${user.uid}/skeletons/${skeletonID}`), {
    //     name,
    //     joints,
    //     bones,
    //     elements
    //   });
    // }
  }, [name, joints, bones, elements]);

  function handleViewportDown(e) {
    if (mode === 'build') {
      const numPointers = (typeof e.data.originalEvent.touches !== 'undefined' ? e.data.originalEvent.touches.length : 1);
  
      if (tool === 'plot' && numPointers === 1) {
        const {x, y} = e.data.global;
        setTouchPos({ x, y });
  
        setHoldTimer(setTimeout(() => {
          const {x: zx, y: zy, z} = zoomOffset;
  
          setJoints({
            ...joints,
            [nextJointID]: {
              x: (x / z + zx),
              y: (y / z + zy)
            }
          });
  
          setElements({
            ...elements,
            [nextElementID]: {
              name: `Element ${nextElementID}`,
              jointIDs: [ nextJointID.toString() ],
              boneIDs: []
            }
          });
  
          setNextJointID(nextJointID + 1);
          setNextElementID(nextElementID + 1);
  
          navigator.vibrate(50);
        }, 500));
      }
      else if (tool === 'connect' && numPointers === 1) {
        setConnectingJointID(-1);
      }
      else if (tool === 'unplot' && numPointers === 1) {
        setDeleteJointID(-1);
      }
    }
  }

  function handleViewportMove(e) {
    console.log('move');
    if (mode === 'build') {
      const numPointers = (typeof e.data.originalEvent.touches !== 'undefined' ? e.data.originalEvent.touches.length : 1);
  
      if (tool === 'plot' && numPointers === 1 && dragJointID > -1) {
        const {x, y} = e.data.global;
        const {x: zx, y: zy, z} = zoomOffset;
        let newJoints = { ...joints };
  
        newJoints[dragJointID] = {
          x: (x / z + zx),
          y: (y / z + zy)
        };
  
        setJoints(newJoints);
      }
      else if (holdTimer !== null) {
        const { x: nx, y: ny } = e.data.global;
        const { x, y } = touchPos;
        const dist = Math.hypot(x - nx, y - ny);
  
        if (dist > 10 || numPointers > 1) {
          clearTimeout(holdTimer);
          setHoldTimer(null);
          setTouchPos({ x: null, y: null });
          console.log('cleared by move');
        }
      }
    }
  }

  function handleViewportUp() {
    if (mode === 'build') {
      if (tool === 'plot') {
        setDragJointID(-1);
        setTouchPos({ x: null, y: null });
    
        if (holdTimer !== null) {
          clearTimeout(holdTimer);
          setHoldTimer(null);
        }
      }
    }
  }

  function handleJointDown(e, id) {
    e.stopPropagation();

    if (mode === 'build') {
      if (tool === 'plot') {
        setDragJointID(id);
      }
      else if (tool === 'connect') {
        if (connectingJointID === -1) {
          setConnectingJointID(id);
        }
        else {
          createConnection(connectingJointID, id);
        }
      }
      else if (tool === 'unplot') {
        if (deleteJointID === -1) {
          setDeleteJointID(id);
        }
        else if (deleteJointID === id) {
          setDeleteJointID(-1);
          deleteJointAndConnectedBones(id);
        }
        else {
          setDeleteJointID(-1);
        }
      }
    }
  }

  function deleteJointAndConnectedBones(id) {
    let newBones = { ...bones };
    Object.keys(newBones).forEach((key) => {
      const bone = newBones[key];
      
      if (bone.id1 === id || bone.id2 === id) {
        delete newBones[key];
      }
    });
    
    let newJoints = { ...joints };
    delete newJoints[id];

    splitElementByJointID(id);

    setBones(newBones);
    setJoints(newJoints);
  }

  function createConnection(id1, id2) {
    const boneExists = (Object.keys(bones).findIndex((id) => ((bones[id].id1 === id1 && bones[id].id2 === id2) || (bones[id].id1 === id2 && bones[id].id2 === id1))) > -1);
    const id1ConnectionCount = (Object.keys(bones).filter((id) => (bones[id].id1 === id1 || bones[id].id2 === id1)).length);
    const id2ConnectionCount = (Object.keys(bones).filter((id) => (bones[id].id1 === id2 || bones[id].id2 === id2)).length);

    if (boneExists) {
      showNotification({
        title: 'Error: Bone Already Exists',
        message: 'The bone connection you are trying to make already exists.',
        icon: <IconX />,
        color: 'red'
      });
    }
    else if (id1ConnectionCount === 2) {
      showNotification({
        title: 'Error: Too Many Connections',
        message: 'The joint you are trying to connect from already has the max number of bones (2).',
        icon: <IconX />,
        color: 'red'
      });
    }
    else if (id2ConnectionCount === 2) {
      showNotification({
        title: 'Error: Too Many Connections',
        message: 'The joint you are trying to connect to already has the max number of bones (2).',
        icon: <IconX />,
        color: 'red'
      });
    }
    else {
      const newBone = { id1, id2 };
      const boneID = nextBoneID;
      setBones({
        ...bones,
        [boneID]: newBone
      });
      setNextBoneID(nextBoneID + 1);

      mergeElementsByBone(newBone, boneID);
    }

    setConnectingJointID(-1);
  }

  function mergeElementsByBone(bone, boneID) {
    const jointID1 = bone.id1;
    const jointID2 = bone.id2;
    let newElements = {};
    let mergedElement = {
      name: `Element ${nextElementID}`,
      jointIDs: [],
      boneIDs: [ boneID.toString() ]
    };

    Object.keys(elements).forEach((elementID) => {
      const element = elements[elementID];

      // If the element contains either joint, merge them into the new element and do not add them to the new element map yet
      if (element.jointIDs.includes(jointID1) || element.jointIDs.includes(jointID2)) {
        mergedElement.jointIDs = [
          ...mergedElement.jointIDs,
          ...element.jointIDs
        ];

        mergedElement.boneIDs = [
          ...mergedElement.boneIDs,
          ...element.boneIDs
        ];
      }
      // Otherwise, just add the element to the new element map
      else {
        newElements = {
          ...newElements,
          [elementID]: {
            ...element
          }
        };
      }
    });

    // Finally, add the new merged element to the elements map
    newElements = {
      ...newElements,
      [nextElementID]: {
        ...mergedElement
      }
    };

    setElements(newElements);

    setNextElementID(nextElementID + 1);
  }

  // jointID = the joint being removed
  function splitElementByJointID(jointID) {
    let elementToSplit = null;
    let newElement1 = {
      name: `Element ${nextElementID}`,
      jointIDs: [],
      boneIDs: []
    };
    let newElement2 = {
      name: `Element ${nextElementID + 1}`,
      jointIDs: [],
      boneIDs: []
    };
    let newElements = {};

    // First, find the element containing the joint, and reconstruct newElements such that it doesn't contain this element
    Object.keys(elements).forEach((elementID) => {
      const element = elements[elementID];

      if (element.jointIDs.includes(jointID)) {
        elementToSplit = {
          ...element
        };
      }
      else {
        newElements = {
          ...newElements,
          [elementID]: {
            ...element
          }
        };
      }
    });

    elementToSplit.boneIDs = elementToSplit.boneIDs.filter((_boneID) => bones[_boneID].id1 !== jointID && bones[_boneID].id2 !== jointID);

    elementToSplit.jointIDs.splice(elementToSplit.jointIDs.indexOf(jointID), 1);

    if (elementToSplit.boneIDs.length > 0) {
      elementToSplit.boneIDs.forEach((_boneID) => {
        const bone = bones[_boneID];
        const doAddToEle1 = (newElement1.boneIDs.length === 0 || newElement1.jointIDs.includes(bone.id1) || newElement1.jointIDs.includes(bone.id2));
  
        if (doAddToEle1) {
          newElement1.boneIDs = [
            ...newElement1.boneIDs,
            _boneID
          ];
  
          newElement1.jointIDs = [
            ...newElement1.jointIDs,
            bone.id1,
            bone.id2
          ];
        }
        else {
          newElement2.boneIDs = [
            ...newElement2.boneIDs,
            _boneID
          ];
  
          newElement2.jointIDs = [
            ...newElement2.jointIDs,
            bone.id1,
            bone.id2
          ];
        }
      });
    }
    else {
      newElement1.jointIDs = [ elementToSplit.jointIDs[0] ];
      newElement2.jointIDs = [ elementToSplit.jointIDs[1] ];
    }

    // Finally, update the state...
    newElements = {
      ...newElements,
      [nextElementID]: {
        ...newElement1
      },
      [nextElementID + 1]: {
        ...newElement2
      }
    };

    setElements(newElements);
    setNextElementID(nextElementID + 2);
  }

  function isJointInElement(jointID, elementID) {
    let jointElementID = '';

    Object.keys(elements).forEach((_eleID) => {
      const _ele = elements[_eleID];

      if (_ele.jointIDs.includes(jointID.toString())) {
        jointElementID = _eleID;
      }
    });

    return (jointElementID === elementID);
  }

  function isBoneInElement(boneID, elementID) {
    let boneElementID = '';

    Object.keys(elements).forEach((_eleID) => {
      const _ele = elements[_eleID];

      if (_ele.boneIDs.includes(boneID.toString())) {
        boneElementID = _eleID;
      }
    });

    return (boneElementID === elementID);
  }

  return (
    <div className={classes.canvasViewport}>
      <Stage options={{
        resizeTo: window,
        backgroundAlpha: 0
      }}>
        <Viewport pointerdown={handleViewportDown} pointermove={handleViewportMove} pointerup={handleViewportUp} onZoomEnd={(x, y, z) => setZoomOffset({x, y, z})}>
          {
            Object.keys(joints).map((id) => (
              <SkeletonJoint
                key={`skeleton_joint_${id}`}
                x={joints[id].x}
                y={joints[id].y}
                willConnect={(connectingJointID === id)}
                willDelete={(deleteJointID === id)}
                willName={(mode === 'name-elements' && isJointInElement(id, Object.keys(elements)[selectedElementIndex]))}
                colorScheme={colorScheme}
                pointerdown={(e) => handleJointDown(e, id)}
              />
            ))
          }
          {
            Object.keys(bones).map((id) => (
              <SkeletonBone
                key={`skeleton_bone_${id}`}
                pos1={{
                  x: joints[bones[id].id1].x,
                  y: joints[bones[id].id1].y
                }}
                pos2={{
                  x: joints[bones[id].id2].x,
                  y: joints[bones[id].id2].y
                }}
                willDelete={(bones[id].id1 === deleteJointID || bones[id].id2 === deleteJointID)}
                willName={(mode === 'name-elements' && isBoneInElement(id, Object.keys(elements)[selectedElementIndex]))}
                colorScheme={colorScheme}
              />
            ))
          }
        </Viewport>
      </Stage>
    </div>
  );
}