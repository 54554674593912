import * as PIXI from 'pixi.js';
import { CustomPIXIComponent } from 'react-pixi-fiber/index';

export default CustomPIXIComponent(
  {
    customDisplayObject: (props) => {
      const node = new PIXI.Graphics();
      node.interactive = true;

      return node;
    },
    customApplyProps: (node, oldProps, newProps) => {
      const {x, y, willConnect, willDelete, willName, colorScheme} = newProps;

      node.clear();
      if (willName) {
        node.lineStyle(2, 0x0000FF, 1);
        node.beginFill(0x0000FF, 0.25);
      }
      else if (willConnect) {
        node.lineStyle(2, 0x00FF00, 1);
        node.beginFill(0x00FF00, 0.25);
      }
      else if (willDelete) {
        node.lineStyle(2, 0xFF0000, 1);
        node.beginFill(0xFF0000, 0.25);
      }
      else {
        if (colorScheme === 'light') {
          node.lineStyle(2, 0x000000, 1);
          node.beginFill(0x000000, 0.25);
        }
        else if (colorScheme === 'dark') {
          node.lineStyle(2, 0xFFFFFF, 1);
          node.beginFill(0xFFFFFF, 0.25);
        }
      }
      node.drawCircle(x, y, 10);
      node.endFill();

      if (typeof oldProps !== 'undefined') {
        node.off('pointerdown', oldProps.pointerdown);
      }

      node.on('pointerdown', newProps.pointerdown);
    }
  },
  "SkeletonJoint"
);